<template>
  <BasicModal :title="title" width="550px" :visible="visible" :confirmLoading="confirmLoading" @ok="ok"
    @cancel="cancel">
    <div class="project-edit-content">
      <BasicForm class="project-edit-form" ref="formRef1" :labelCol="{ span: 6 }" :formItemsMap="formItemsMap1"
        v-model:formData="formData1"></BasicForm>
    </div>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  ref,
  defineEmits,
  reactive,
  onMounted,
  computed,
} from "vue";
import { message } from "ant-design-vue";
import { apiConfig } from "@/api/centerMonitor/viewConfig.js";
import { apiDictListByCode } from "@/api/common";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: "edit",
  },
});

const title = computed(() => {
  return props.type == "add"
    ? "添加视图"
    : props.type == "edit"
      ? "编辑视图"
      : "复制视图";
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);

onMounted(() => {
  initFormItems();
  if (props.info) {
    initDetail();
  }
});

const initDetail = () => {
  apiConfig.detail(props.info.id).then((res) => {
    formData1.name = res.result.name;
    formData1.bizProjectId = res.result.bizProjectId;
    formData1.viewType = res.result.viewType + "";
    formData1.sort = res.result.sort;
  });
};

const formItemsMap1 = reactive({
  name: {
    label: "视图名称",
    prop: "name",
    type: "input",
    span: 18,
    required: true,
  },
  column: {
    label: "所属项目",
    prop: "bizProjectId",
    type: "select",
    required: true,
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    options: [],
    span: 18,
    disabled: props.info ? true : false,
    requiredMessage: "请选择",
  },
  type: {
    label: "视图类型",
    prop: "viewType",
    type: "select",
    required: true,
    options: [],
    span: 18,
    disabled: props.info ? true : false,
    requiredMessage: "请选择",
  },
  sort: {
    label: "排序",
    prop: "sort",
    type: "input",
    span: 18,
    required: true,
  },
});
const formData1 = reactive({
  bizProjectId: "",
  name: "",
  viewType: "",
  sort: ""
});

const initFormItems = async () => {
  apiDictListByCode("VIEW_TYPE").then((res) => {
    formItemsMap1.type.options = res.result || [];
  });

  apiConfig.getProject().then((res) => {
    formItemsMap1.column.options = res.result || [];
    console.log(2323, formItemsMap1.column.options);
  });
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = { ...formData1 };
    let messages;
    if (props.type === "add") {
      await apiConfig.add(params);
      messages = '新增成功！';
    }
    if (props.type === "edit") {
      params.id = props.info.id;
      await apiConfig.edit(params);
      messages = '编辑成功！';
    }
    if (props.type === "copy") {
      await apiConfig.copy({
        id: props.info.id,
        name: params.name,
        sort: params.sort
      });
      messages = '复制成功！';
    }
    message.success(`${messages}`);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.actions {
  text-align: right;

  button {
    margin-left: 10px;
  }
}

.project-edit-content {
  gap: 20px;

  /deep/.ant-row {
    justify-content: space-between;
  }
}

.project-edit-form {
  flex: 1;
}

/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }

  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  input {
    width: 320px;
  }
}

.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
