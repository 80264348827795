<template>
  <div class="common-wrapper">
    <div class="content-header">
      <categorySearch :source="filterSource" class="searchBlock" @change="handleSearch" />
      <a-button type="primary" @click="handleAdd">添加</a-button>
    </div>
    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange" :scroll="{ y: tableHeight }">
      <template #slotId="{ row }">
        <a @click="goEditView(row)">{{ row.id }}</a>
      </template>
      <template #slotStatus="{ row }">
        <a-tag color="green" v-if="row.status == 1">已发布</a-tag>
        <a-tag color="default" v-else>未发布</a-tag>
      </template>
    </basic-table>
    <edit 
      v-if="editModalVisible"
      v-model:visible="editModalVisible"
      :info="currentRow"
      :type="type"
      @success="editSuccess"
    />
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'ViewConfig'
// })
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from '@/components/basic/table'
import { reactive, ref, onMounted, onBeforeUnmount, } from 'vue'
import { apiConfig } from "@/api/centerMonitor/viewConfig.js";
import { Modal, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import edit from './components/edit.vue'
// import _ from "lodash";

const filterSource = [
  {
    label: '项目名称',
    type: 'input',
    bindKey: 'projectName',
    placeholder: '请输入项目名称'
  },
  {
    label: '视图名称',
    type: 'input',
    bindKey: 'viewName',
    placeholder: '请输入视图名称'
  },
]

const tableConfig = reactive({
  pagination: true, //分页
  isPageBottom: true, //分页在底部
  remote: true,  //!!!!!!!!! 自动加载
  remoteApi: apiConfig.page,//!!!!!!!!!!自动加载
  immediate: true, //立即加载数据
  rowKey: "id",
  queryParams: {}, //过滤参数
  columns: [
    {
      title: "ID",
      type: "customize",
      key: "id",
      slotName: "slotId",
      width: 100
    },
    {
      title: "视图名称",
      type: "text",
      key: "name",
    },
    {
      title: "项目名称",
      type: "text",
      key: "projectName",
    },
    {
      title: "项目编码",
      type: "text",
      key: "bizProjectId",
    },
    {
      title: "视图类型",
      type: "text",
      key: "typeName",
    },
    {
      title: "定制方式",
      type: "text",
      key: "customType",
      formatter(val) {
        return val == 1?'自定义':'低代码';
      },
    },
    {
      title: "状态",
      type: "customize",
      key: "CST",
      slotName: "slotStatus",
      width: 120
    },
    {
      title: "排序",
      type: "text",
      key: "sort",
      width: 90
    },
    {
      title: "创建人",
      type: "text",
      key: "creatorName",
    },
    {
      title: "创建时间",
      type: "text",
      key: "createTime",
      formatter(val) {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ],
  action:{
    title: "操作",
    width: 130,
    fixed: "right",
    items: [
      {
        icon: "FormOutlined",
        iconTip: "编辑",
        type: "icon",

        onClick: (row) => {
          handleEdit(row);
        },
      },
      {
        icon: "CopyOutlined",
        iconTip: "复制",
        type: "icon",
        visibleFn: (row) => row.customType === 0,
        onClick: (row) => {
          handleCopy(row);
        },
      },
      {
        icon: "UpSquareOutlined",
        iconTip: "发布",
        type: "icon",
        visibleFn: (row) => !row.status,
        onClick: (row) => {
          handleStatus(1, row);
        },
      },
      {
        icon: "DownSquareOutlined",
        iconTip: "下架",
        type: "icon",
        visibleFn: (row) => row.status,
        onClick: (row) => {
          handleStatus(2, row);
        },
      },
      {
        icon: "DeleteOutlined",
        iconTip: "删除",
        type: "icon",
        onClick: (row) => {
          handleDel(row);
        },
      },
    ],
  }
})

const tableHeight = ref(500)
const changeTableHeight = () => {
  tableHeight.value = document.body.clientHeight - 350
}

const handleSearch = (data)=>{
  tableConfig.queryParams = data;
  tableRef.value.reload()
}


const goEditView = ({ customType, url }) => {
  console.log(url)
  if (customType === 0) {
    window.open(url, "_blank");
  } else if (customType === 1) {
    Modal.info({
      title: '提示',
      content: '所选视图，为自定义开发定制页面，不可在此编辑或查看！',
    });
  }
}

const editModalVisible = ref(false)
const currentRow = ref(null)
const tableRef = ref();

const handleEdit = (row)=>{
  type.value = 'edit'
  currentRow.value = row
  editModalVisible.value = true
}

//新增
const type = ref('add');
const handleAdd = ()=>{
  type.value = 'add'
  currentRow.value = null
  editModalVisible.value = true
}
const handleDel = ({id})=>{
  Modal.confirm({
    title: "提醒",
    content: "请确认是否删除？",
    async onOk() {
      await apiConfig.delete(id);
      message.success("删除成功");
      tableRef.value.refresh();
    },
  });
}

const handleStatus = (type, {id})=>{
  Modal.confirm({
    title: "提醒",
    content: `请确认是否${type == 1?'发布':"下架"}？`,
    async onOk() {
      await apiConfig.status(id);
      message.success("操作成功");
      tableRef.value.refresh();
    },
  });
}

//复制
const handleCopy = (row)=>{
  type.value = 'copy'
  currentRow.value = row
  editModalVisible.value = true
}

const editSuccess = ()=>{
  editModalVisible.value = false
  tableRef.value.refresh();
}

onMounted(() => {
  changeTableHeight()
  window.addEventListener('resize', changeTableHeight)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', changeTableHeight)
})


</script>
<style lang='less' scoped>
.content-header{
  display: flex;
  justify-content: space-between;
}
</style>

